import React, { useState } from 'react';
import { MessageCircle, Repeat, Heart, Share, Play, Bookmark } from 'lucide-react';
import { Card, CardContent } from './card';
import ProfileImage from './ProfileImage';
import './TweetCard.css';

const SkeletonImage = () => (
  <div className="skeleton-image animate-pulse bg-gray-200 dark:bg-gray-700"></div>
);

const ImageWithSkeleton = ({ src, alt, className }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className={`relative ${className}`}>
      {!imageLoaded && <SkeletonImage />}
      <img
        src={src}
        alt={alt}
        className={`w-full h-full object-cover ${imageLoaded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  );
};

const TwitterIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill={color}>
    <path d="M23.954 4.569c-.885.392-1.83.656-2.825.775 1.014-.608 1.794-1.569 2.163-2.724-.951.564-2.005.974-3.127 1.195-.897-.959-2.178-1.559-3.594-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124-4.09-.205-7.719-2.165-10.148-5.144-.424.729-.666 1.576-.666 2.476 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.23-.616v.062c0 2.385 1.693 4.374 3.946 4.827-.413.112-.849.171-1.296.171-.317 0-.626-.03-.927-.086.631 1.953 2.445 3.376 4.604 3.416-1.68 1.319-3.809 2.107-6.115 2.107-.398 0-.79-.023-1.175-.069 2.179 1.397 4.768 2.212 7.548 2.212 9.051 0 14-7.496 14-13.986 0-.21 0-.423-.015-.634.961-.695 1.8-1.562 2.46-2.549z" />
  </svg>
);

const TweetCard = ({ tweet }) => {
  const isLikeFormat = tweet.source === 'like';
  const isUserTweetFormat = tweet.source === 'user_tweet';
  const tweetId = tweet.id || 'unknown';
  const tweetUrl = tweet.url || '#';
  const tweetText = tweet.text || tweet.full_text || '';
  const tweetDate = tweet.created_at || new Date().toISOString();
  const userName = tweet.user?.name || 'Unknown User';
  const userHandle = tweet.user?.screen_name || 'unknown';
  const userProfilePic = tweet.user?.profile_image_url || '/default-profile-pic.png';
  const tweetMedia = tweet.medias || [];

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderTweetText = (text) => {
    if (!text) return null;

    const decodedText = decodeHTMLEntities(text);
    const regex = /(https?:\/\/[^\s]+)|(@\w+)/g;
    const parts = decodedText.split(regex);

    return parts.map((part, index) => {
      if (!part) return null;

      if (part.match(/^https?:\/\//)) {
        return (
          <a
            key={`link-${index}`}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="text-twitter-blue hover:underline"
          >
            {part}
          </a>
        );
      } else if (part.startsWith('@')) {
        const username = part.slice(1);
        return (
          <a
            key={`mention-${index}`}
            href={`https://x.com/${username}`}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            {part}
          </a>
        );
      } else {
        return part.split(/\n/).map((line, lineIndex) => (
          <React.Fragment key={`text-${index}-${lineIndex}`}>
            {line}
            {lineIndex < part.split(/\n/).length - 1 && <br />}
          </React.Fragment>
        ));
      }
    });
  };

  const renderMedia = (media) => {
    if (!media || media.length === 0) {
      return null;
    }

    return media.map((item, index) => {
      if (item.type === 'photo' || item.url.match(/\.(jpeg|jpg|gif|png)$/i)) {
        return (
          <ImageWithSkeleton
            key={`media-${index}`}
            src={item.url}
            alt={`Tweet media ${index + 1}`}
            className="tweet-media"
          />
        );
      } else if (item.type === 'video') {
        return (
          <div key={`video-${index}`} className="tweet-media video-container">
            <ImageWithSkeleton
              src={item.url}
              alt={`Video thumbnail ${index + 1}`}
              className="w-full h-full"
            />
            <div className="video-overlay">
              <Play size={48} className="text-white opacity-75" />
            </div>
          </div>
        );
      }
      return null;
    });
  };

  const decodeHTMLEntities = (text) => {
    if (!text) return '';
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  return (
    <Card key={tweetId} className="tweet-card">
      <CardContent className="p-4">
        <div className="flex items-start mb-2">
          <ProfileImage
            src={userProfilePic}
            alt={userName}
            userHandle={userHandle}
            className="w-12 h-12 flex-shrink-0 mr-3"
          />
          <div className="flex-grow">
            <div className="flex items-center justify-between">
              <div>
                <span className="font-bold tweet-name">{userName}</span>
                <span className="tweet-handle ml-2">@{userHandle}</span>
              </div>
              <span className="tweet-date">{formatDate(tweetDate)}</span>
            </div>
            <div className="mt-2 tweet-text">{renderTweetText(tweetText)}</div>
            {tweetMedia && tweetMedia.length > 0 && (
              <div className="mt-2 grid grid-cols-2 gap-2">
                {renderMedia(tweetMedia)}
              </div>
            )}
            {tweet.quote_status && (
              <div className="mt-4 quoted-tweet">
                <p className="font-bold tweet-name">{tweet.quote_status.user.name} @{tweet.quote_status.user.handle}</p>
                <div className="tweet-text">{renderTweetText(tweet.quote_status.text)}</div>
                {tweet.quote_status.media && tweet.quote_status.media.length > 0 && (
                  <div className="mt-2 grid grid-cols-2 gap-2">
                    {renderMedia(tweet.quote_status.media)}
                  </div>
                )}
              </div>
            )}
            <div className="mt-4 flex justify-between tweet-actions">
              <button className="tweet-action-button">
                <MessageCircle size={18} className="mr-2" />
                <span>{tweet.reply_count || 0}</span>
              </button>
              <button className="tweet-action-button">
                <Repeat size={18} className="mr-2" />
                <span>{tweet.retweet_count || 0}</span>
              </button>
              <a
                href={tweetUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="tweet-action-button"
              >
                <Share size={18} className="mr-2" />
                <span>View on Twitter</span>
              </a>
              {isLikeFormat ? (
                <Heart size={18} className="text-red-500" />
              ) : isUserTweetFormat ? (
                <TwitterIcon size={18} color="#1DA1F2" />
              ) : (
                <Bookmark size={18} className="text-blue-500" />
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default TweetCard;