import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import TwitterSearch from './components/TwitterSearch';
import Insights from './components/Insights';
import TabbedNavigation from './components/TabbedNavigation';
import ErrorBoundary from './ErrorBoundary';
import { Moon, Sun } from 'lucide-react';
import InsightsIcon from './components/ui/InsightsIcon';
import './App.css';

const TwitterIcon = ({ size = 24, color = 'currentColor' }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill={color}>
    <path d="M23.954 4.569c-.885.392-1.83.656-2.825.775 1.014-.608 1.794-1.569 2.163-2.724-.951.564-2.005.974-3.127 1.195-.897-.959-2.178-1.559-3.594-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124-4.09-.205-7.719-2.165-10.148-5.144-.424.729-.666 1.576-.666 2.476 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.23-.616v.062c0 2.385 1.693 4.374 3.946 4.827-.413.112-.849.171-1.296.171-.317 0-.626-.03-.927-.086.631 1.953 2.445 3.376 4.604 3.416-1.68 1.319-3.809 2.107-6.115 2.107-.398 0-.79-.023-1.175-.069 2.179 1.397 4.768 2.212 7.548 2.212 9.051 0 14-7.496 14-13.986 0-.21 0-.423-.015-.634.961-.695 1.8-1.562 2.46-2.549z" />
  </svg>
);

const AppContent = () => {
  const [darkMode, setDarkMode] = useState(() => {
    const saved = localStorage.getItem('darkMode');
    return saved !== null ? JSON.parse(saved) : false;
  });
  const location = useLocation();

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [darkMode]);

  const isInsightsPage = location.pathname === '/insights';

  return (
    <div className="app-container">
      <div className="container mx-auto p-4 max-w-2xl">
        <header className="flex flex-col sm:flex-row justify-between items-center mb-4">
          <div className="flex items-center mb-2 sm:mb-0">
            <span className="text-[#1DA1F2] mr-2">
              {isInsightsPage ? (
                <InsightsIcon size={24} color={darkMode ? "#ffffff" : "#1DA1F2"} />
              ) : (
                <TwitterIcon size={24} color={darkMode ? "#ffffff" : "#1DA1F2"} />
              )}
            </span>
            <TabbedNavigation />
          </div>
          <button onClick={toggleDarkMode} className="dark-mode-toggle">
            {darkMode ? <Sun className="text-yellow-400" /> : <Moon className="text-gray-600" />}
          </button>
        </header>
        <Routes>
          <Route path="/" element={<TwitterSearch />} />
          <Route path="/insights" element={<Insights />} />
        </Routes>
      </div>
    </div>
  );
};

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <AppContent />
      </ErrorBoundary>
    </Router>
  );
}

export default App;