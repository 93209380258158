import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Filter, Shuffle } from 'lucide-react';
import RedditCard from './ui/RedditCard';
import TweetCard from './ui/TweetCard';
import FilterPopover from './ui/FilterPopover';
import './TwitterSearch.css';

const TWEETS_PER_PAGE = 10;
const LIKES_FILE = '/exports/likes/merged_tweets.json';
const BOOKMARKS_FILE = '/exports/bookmarks/bookmarks.json';
const USER_TWEETS_FILE = '/exports/user_tweets/20241015_user_tweets.json';
const REDDIT_SAVES_FILE = '/exports/reddit/reddit-export.json';

const TwitterSearch = ({ darkMode }) => {
  const [allTweets, setAllTweets] = useState([]);
  const [displayedTweets, setDisplayedTweets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalTweets, setTotalTweets] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    likes: true,
    bookmarks: true,
    user_tweets: false,
    reddit: false,
    withImages: true,
    withVideos: true,
    textOnly: true,
    over18: false,
    onlyOver18: false,
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const loadTweets = useCallback(async () => {
    try {
      const [likesResponse, bookmarksResponse, userTweetsResponse, redditResponse] = await Promise.all([
        fetch(LIKES_FILE),
        fetch(BOOKMARKS_FILE),
        fetch(USER_TWEETS_FILE),
        fetch(REDDIT_SAVES_FILE)
      ]);

      if (!likesResponse.ok || !bookmarksResponse.ok || !userTweetsResponse.ok || !redditResponse.ok) {
        throw new Error(`Failed to load data: ${!likesResponse.ok ? 'likes ' : ''}${!bookmarksResponse.ok ? 'bookmarks ' : ''}${!redditResponse.ok ? 'reddit' : ''}`);
      }

      const likesData = await likesResponse.json();
      const bookmarksData = await bookmarksResponse.json();
      const userTweetsData = await userTweetsResponse.json();      
      const redditData = await redditResponse.json();
      
      const processedLikes = likesData
        .filter(tweet => !tweet.skip)
        .map(tweet => ({
          ...tweet,
          text: decodeHTMLEntities(tweet.full_text || tweet.text || ''),
          source: 'like',
          quote_status: tweet.quoted_status ? {
            ...tweet.quoted_status,
            text: decodeHTMLEntities(tweet.quoted_status.full_text || tweet.quoted_status.text || '')
          } : null
        }));

      const processedBookmarks = bookmarksData.data.map(bookmark => ({
        id: bookmark.id,
        text: decodeHTMLEntities(bookmark.text),
        created_at: bookmark.date,
        user: {
          name: bookmark.user.name,
          screen_name: bookmark.user.handle,
          profile_image_url: bookmark.user.profilePicUrl
        },
        url: bookmark.url,
        medias: bookmark.media,
        source: 'bookmark',
        retweet_count: bookmark.retweet_count,
        reply_count: bookmark.reply_count,
        quote_status: bookmark.quote_status ? {
          ...bookmark.quote_status,
          text: decodeHTMLEntities(bookmark.quote_status.text)
        } : null
      }));

      const processedUserTweets = userTweetsData.map(tweet => ({
        id: tweet.id,
        text: decodeHTMLEntities(tweet.full_text),
        created_at: tweet.date,
        user: {
          name: tweet.user.name,
          screen_name: tweet.user.screen_name          ,
          profile_image_url: tweet.user.profile_image_url
        },
        url: tweet.url,
        medias: tweet.media,
        source: 'user_tweet',
        retweet_count: tweet.retweet_count,
        reply_count: tweet.reply_count,
        quote_status: tweet.quote_status ? {
          ...tweet.quote_status,
          text: decodeHTMLEntities(tweet.quote_status.full_text)
        } : null
      }));
      
      const processRedditItem = (item, index = 0) => ({
        id: item.id,
        title: item.title || '',
        text: item.selftext || item.body || '',
        created_at: new Date(item.created_utc * 1000).toISOString(),
        author: item.author?.name || '[deleted]',
        subreddit: item.subreddit.display_name,
        url: `https://new.reddit.com${item.permalink}`,
        ups: item.ups,
        downs: item.downs,
        num_comments: item.num_comments || 0,
        source: 'reddit',
        thumbnail: item.thumbnail,
        is_self: item.is_self,
        over_18: item.over_18,
        _replies: item._replies,
        index: index
      });

      const processedRedditSaves = redditData.saved
        .filter(post => !post.over_18)
        .flatMap((post, postIndex) => {
          const processedPost = processRedditItem(post);
          if (post.comments) {
            const processedComments = post.comments
              .filter(comment => !comment.over_18)
              .map((comment, commentIndex) =>
                processRedditItem(comment, commentIndex + 1)
              );
            return [processedPost, ...processedComments];
          }
          return [processedPost];
        });

      const mergedData = [...processedLikes, ...processedBookmarks, ...processedUserTweets, ...processedRedditSaves];
      setAllTweets(mergedData);
      setDisplayedTweets(mergedData.slice(0, TWEETS_PER_PAGE));
      setTotalTweets(mergedData.length);
      setCurrentPage(1);
      setIsLoading(false);
    } catch (err) {
      console.error('Failed to load data:', err);
      setError(`Failed to load data: ${err.message}`);
      setIsLoading(false);
    }
  }, []);

  const filteredTweets = useMemo(() => {
    return allTweets.filter((tweet) => {
      const tweetText = tweet.text || tweet.title || '';
      const matchesSearch = tweetText.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesSource =
        (filters.likes && tweet.source === 'like') ||
        (filters.bookmarks && tweet.source === 'bookmark') ||
        (filters.user_tweets && tweet.source === 'user_tweet') ||
        (filters.reddit && tweet.source === 'reddit');

      const hasMedia = tweet.medias && tweet.medias.length > 0;
      const hasImages = hasMedia && tweet.medias.some(media => media.type === 'photo');
      const hasVideos = hasMedia && tweet.medias.some(media => media.type === 'video');

      const matchesMedia =
        (filters.withImages && hasImages) ||
        (filters.withVideos && hasVideos) ||
        (filters.textOnly && !hasMedia);

      const isOver18 = tweet.over_18 ?? false;

      const matchesAdultContent =
        (filters.onlyOver18 && isOver18) ||
        (!filters.onlyOver18 && (filters.over18 || !isOver18));

      return matchesSearch && matchesSource && (tweet.source === 'reddit' || matchesMedia) && matchesAdultContent;
    });
  }, [allTweets, searchTerm, filters]);

  useEffect(() => {
    setDisplayedTweets(filteredTweets.slice(0, TWEETS_PER_PAGE));
    setCurrentPage(1);
  }, [filteredTweets]);

  useEffect(() => {
    loadTweets();
  }, [loadTweets]);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    const nextTweets = filteredTweets.slice(0, nextPage * TWEETS_PER_PAGE);
    setDisplayedTweets(nextTweets);
    setCurrentPage(nextPage);
  };

  const handleShuffle = () => {
    const shuffled = [...filteredTweets].sort(() => 0.5 - Math.random());
    setDisplayedTweets(shuffled.slice(0, TWEETS_PER_PAGE));
    setCurrentPage(1);
  };

  const decodeHTMLEntities = (text) => {
    if (!text) return '';
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
  };

  const renderTweet = (tweet) => {
    if (!tweet) return null;

    if (tweet.source === 'reddit') {
      return <RedditCard key={tweet.id} post={tweet} />;
    }

    return <TweetCard key={tweet.id} tweet={tweet} />;
  };

  if (isLoading) {
    return <div className="twitter-search text-center mt-8 loading-text">Loading...</div>;
  }

  if (error) {
    return <div className="twitter-search text-center mt-8 error-message">{error}</div>;
  }

  return (
    <div className={`twitter-search mt-8 ${darkMode ? 'dark-mode' : ''}`}>
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
        <button
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className="filter-button"
          aria-label="Open filters"
        >
          <Filter size={20} />
        </button>
        {isFilterOpen && (
          <FilterPopover
            filters={filters}
            setFilters={setFilters}
            onClose={() => setIsFilterOpen(false)}
          />
        )}
      </div>
      <p className="mb-4 subtitle">
        Explore & rediscover my liked and bookmarked tweets as well as my Reddit saves!
      </p>
      <p className="mb-4 total-tweets">
        Total saved items: <span className="font-italic">{totalTweets}</span> (
        Likes: <span className="font-italic">{allTweets.filter(t => t.source === 'like').length}</span>,
        Bookmarks: <span className="font-italic">{allTweets.filter(t => t.source === 'bookmark').length}</span>,
        User Tweets: <span className="font-italic">{allTweets.filter(t => t.source === 'user_tweet').length}</span>,
        Reddit: <span className="font-italic">{allTweets.filter(t => t.source === 'reddit').length}</span>)
      </p>
      {searchTerm && (
        <p className="mb-4 search-results">
          Found {filteredTweets.length} results for "{searchTerm}"
        </p>
      )}
      <div className="mb-4">
        <button onClick={handleShuffle} className="shuffle-button">
          <Shuffle className="inline-block mr-2" size={20} />
          Shuffle Saves
        </button>
      </div>
      <div>{displayedTweets.map(renderTweet)}</div>
      {displayedTweets.length < filteredTweets.length && (
        <button
          onClick={handleLoadMore}
          className="load-more-button"
        >
          Load More
        </button>
      )}
    </div>
  );
};

export default TwitterSearch;